<template>
  <div class="yimiao">
    <div>
      <div>
        <!-- <van-cell-group v-if="datafrom.type == 1">
          <van-field v-model="datafrom.OCodeName"
            required
            @click="OCodeshow = true"
            readonly="readonly"
            label="所属社区"
            placeholder="请选择您所属社区" />
          <van-popup v-model="OCodeshow"
            position="bottom"
            get-container="body">
            <van-cascader title="请选择所属社区"
              :options="ocodelist"
              @close="OCodeshow = false"
              :field-names="{
                text: 'OrganName',
                value: 'OrganCode',
                children: 'children',
              }"
              @finish="onocode" />
          </van-popup>
        </van-cell-group>
        <van-cell-group v-if="datafrom.type == 1">
          <van-field v-model="datafrom.Region"
            required
            @click="idListRId = true"
            readonly="readonly"
            label="所住小区"
            placeholder="请选择您所住小区" />
          <van-popup v-model="idListRId"
            position="bottom"
            get-container="body">
            <van-picker show-toolbar
              :columns="ridlist"
              value-key="Title"
              @cancel="idListRId = false"
              @confirm="onidListRId">
            </van-picker>
          </van-popup>
        </van-cell-group>
        <van-cell-group v-if="datafrom.type == 1">
          <van-field v-model="datafrom.Region"
            required
            @click="idListRId = true"
            readonly="readonly"
            label="服务站"
            placeholder="请选择服务站" />
          <van-popup v-model="idListRId"
            position="bottom"
            get-container="body">
            <van-picker show-toolbar
              :columns="ridlist"
              value-key="Title"
              @cancel="idListRId = false"
              @confirm="onidListRId">
            </van-picker>
          </van-popup>
        </van-cell-group> -->
        <van-cell-group>
          <van-field
            v-model="datafrom.Name"
            required
            label=" 名 称 "
            placeholder="请输入名称"
          />
        </van-cell-group>
        <van-cell-group>
          <van-field
            v-model="datafrom.SortNum"
            label="排 序"
            required
            placeholder="请输入排序码"
          />
        </van-cell-group>
        <van-cell-group v-if="datafrom.type == 2">
          <van-field
            v-model="datafrom.Amount"
            label="金 额"
            required
            placeholder="请输入金额"
          />
        </van-cell-group>
        <van-cell-group v-if="datafrom.type == 2">
          <van-field
            v-model="datafrom.MUnit"
            label="单 位"
            required
            placeholder="请输入单位"
          />
        </van-cell-group>
        <van-cell-group v-if="datafrom.type == 2">
          <van-field
            v-model="datafrom.SSId"
            required
            @click="fuwushow = true"
            readonly="readonly"
            label="服务分类"
            placeholder="请选择服务分类"
          />
          <van-popup v-model="fuwushow" position="bottom" get-container="body">
            <van-picker
              show-toolbar
              :columns="fuwuLists"
              value-key="Name"
              @cancel="fuwushow = false"
              @confirm="onfuwushow"
            >
            </van-picker>
          </van-popup>
        </van-cell-group>
        <van-cell-group v-if="datafrom.type == 1">
          <van-field
            v-model="datafrom.SerKindDesc"
            required
            @click="sexshow = true"
            readonly="readonly"
            label="服务类别"
            placeholder="请选择服务类别"
          />
          <van-popup v-model="sexshow" position="bottom" get-container="body">
            <van-picker
              show-toolbar
              :columns="SerKindlist"
              value-key="Name"
              @cancel="sexshow = false"
              @confirm="onsexshow"
            >
            </van-picker>
          </van-popup>
        </van-cell-group>
        <van-cell-group>
          <van-field
            v-model="datafrom.Describe"
            rows="3"
            autosize
            label="服务描述"
            type="textarea"
            placeholder="请输入服务描述"
          />
        </van-cell-group>
        <van-cell-group>
          <van-field
            v-model="datafrom.Remark"
            label=" 备 注 "
            placeholder="请输入备注"
          />
        </van-cell-group>
        <div>
          <span style="font-size: 14px; color: #333; margin: 0 17px"
            >图片上传:</span
          >
          <van-uploader
            v-model="fileList"
            multiple
            class="imgs"
            :max-count="1"
            :after-read="afterRead"
            :deletable="true"
            @delete="shanchu"
            style="margin: 15px 22px"
          />
        </div>
      </div>
      <!-- <div class="buttons">
        <van-button type="info" @click="postfrom()">提交</van-button>
      </div> -->
      <div class="buttons">
        <van-button type="info" style="margin: 10px 0" @click="postfrom()"
          >提交</van-button
        >
        <van-button
          style="border: 1px solid #e6e6e6; margin: 10px 0"
          type="
          default"
          @click="fanhui()"
          >返回</van-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Dialog } from "vant";
import { Toast } from "vant";
import { getToken, setToken } from "@/utils/auth";
Vue.use(Toast);
import {
  WeGetRegionList,
  WeGetBuildRoomList,
  WeGetAppletOrganList,
} from "@/api/yeguang";
import axios from "axios";
import config from "@/config";
import {
  WeSaveStaServiceSort,
  WeSaveProService,
  WeGetStaServiceSortPage,
} from "@/api/Lycal";
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      ridlist: [],
      BRIdlist: [],
      ocodelist: [],
      fuwuLists: [],
      acctoken: "",
      SerKindlist: [
        { value: 1, Name: "助餐" },
        { value: 2, Name: "助洁" },
        { value: 3, Name: "助行" },
        { value: 4, Name: "助浴" },
        { value: 5, Name: "助医" },
        { value: 6, Name: "康复" },
        { value: 7, Name: "巡访关爱" },
        { value: 8, Name: "辅助租赁" },
        { value: 99, Name: "其他" },
      ],
      idList: {
        organCode: "",
        RId: undefined,
      },
      datafrom: {
        SSId: 0, //分类Id、
        SerKind: undefined, //服务类别（1.助餐；2.助洁；3.助行；4.助浴；5.助医；6.康复；7.巡访关爱；99.其他）、
        SerKindName: "",
        Name: "", //名称、
        Describe: "", //描述、
        Img: "", //图片、
        SortNum: "", //排序号、
        Status: "1", //状态（1启用、2禁用）、
        Remark: "", //备注
      },
      idListRId: false,
      OCodeshow: false,
      fuwushow: false,
      sexshow: false,
      fileList: [],
      uploadImgs: [],
    };
  },
  created() {
    this.acctoken = getToken();
    var dataFrom = JSON.parse(this.$route.query.id);
    this.datafrom = dataFrom;
    this.gitocodelist();
    this.getStaServiceList();
  },
  methods: {
    // 图片上传
    afterRead(file) {
      let c = {
        headers: { "Content-Type": "multipart/form-data" },
      };
      var formData = new FormData(); //构造一个 FormData，把后台需要发送的参数添加
      formData.append("file", file.file);
      // 添加请求头
      axios.post(config.apiUploadUrl, formData, c).then((res) => {
        if (res.data.code === 0) {
          var arr = this.uploadImgs;
          arr.push(res.data.data);
          this.uploadImgs = arr;
        }
        this.datafrom.Img = res.data.data;
      });
    },
    // 图片删除
    shanchu: function () {
      this.datafrom.Img = "";
    },
    // 获取服务分类
    getStaServiceList() {
      WeGetStaServiceSortPage({ accToken: this.acctoken }).then((res) => {
        console.log(res);
        this.fuwuLists = res.data.data;
      });
    },
    // 提交
    postfrom: function () {
      if (this.datafrom.type == 2) {
        if (this.datafrom.Name == false) {
          Toast.fail("请输入名称");
          return false;
        }
        if (this.datafrom.SortNum == false) {
          Toast.fail("请选择排序");
          return false;
        }
        if (this.datafrom.Amount == false) {
          Toast.fail("请输入金额");
          return false;
        }
        if (this.datafrom.MUnit == false) {
          Toast.fail("请输入单位");
          return false;
        }
        if (this.datafrom.SSId == false) {
          Toast.fail("请选择服务分类");
          return false;
        }
        this.datafrom.accToken = this.acctoken;
        WeSaveProService(this.datafrom).then((res) => {
          if (res.data.code == 0) {
            Dialog.alert({
              title: "提示",
              message: "提交成功!",
            }).then(() => {
              this.$router.push({
                path: "/CalYl/service",
              });
            });
          } else {
            Dialog.alert({
              title: "提示",
              message: "提交失败!" + res.data.msg,
            }).then(() => {
              // on close
            });
          }
        });
      } else if (this.datafrom.type == 1) {
        if (this.datafrom.Name == false) {
          Toast.fail("请输入名称");
          return false;
        }
        if (this.datafrom.SortNum == false) {
          Toast.fail("请选择排序");
          return false;
        }
        if (this.datafrom.SerKindDesc == false) {
          Toast.fail("请选择服务类别");
          return false;
        }
        this.datafrom.accToken = this.acctoken;
        WeSaveStaServiceSort(this.datafrom).then((res) => {
          if (res.data.code == 0) {
            Dialog.alert({
              title: "提示",
              message: "提交成功!",
            }).then(() => {
              this.$router.push({
                path: "/CalYl/service",
              });
            });
          } else {
            Dialog.alert({
              title: "提示",
              message: "提交失败!" + res.data.msg,
            }).then(() => {
              // on close
            });
          }
        });
      }
    },
    fanhui: function () {
      this.$router.push({
        name: "/CalYl/service",
      });
    },
    // 服务类型选择器
    onsexshow(val) {
      this.datafrom.SerKind = val.value;
      this.datafrom.SerKindDesc = val.Name;
      this.sexshow = false;
    },
    // 服务分类选择器
    onfuwushow(val) {
      this.datafrom.SSId = val.SSId;
      this.datafrom.SSIdName = val.Name;
      this.fuwushow = false;
    },
    gitocodelist() {
      WeGetAppletOrganList().then((res) => {
        this.ocodelist = res.data.data;
      });
    },
    // 小区列表
    RegionList: function (row) {
      WeGetRegionList({ organCode: row }).then((res) => {
        this.ridlist = res.data.data;
      });
    },
    // 房屋列表
    BuildRoomList: function (val) {
      WeGetBuildRoomList({
        bId: this.idList.BId,
        buId: this.idList.buId,
        bfId: val,
      }).then((res) => {
        console.log(res);
        this.BRIdlist = res.data.data;
      });
    },
    // 小区选择器
    onidListRId(val) {
      this.datafrom.RId = val.RId;
      this.datafrom.Region = val.Title;
      this.idListRId = false;
      this.BuildList(val.RId);
    },
    // 社区
    onocode(val) {
      console.log(val);
      this.datafrom.OCodeName = val.selectedOptions[0].OrganName;
      this.RegionList(val.value);
      this.OCodeshow = false;
    },
    dateformat(val) {
      // 时间格式化事件 yy-mm-dd
      let year = val.getFullYear();
      let month = val.getMonth() + 1;
      let day = val.getDate();
      let houser = val.getHours();
      let Minutes = val.getMinutes();
      if (month >= 1 && month <= 9) {
        month = `0${month}`;
      }
      if (day >= 1 && day <= 9) {
        day = `0${day}`;
      }
      if (houser >= 1 && houser <= 9) {
        houser = `0${houser}`;
      }
      if (Minutes >= 1 && Minutes <= 9) {
        Minutes = `0${Minutes}`;
      }
      return `${year}-${month}-${day} ${houser}:${Minutes}`;
    },
  },
};
</script>
<style>
.yimiao .inputFrom {
  margin: 15px 8%;
}
.yimiao .inputFrom input {
  width: 80%;
  margin: 8px 8%;
  padding: 8px 5px;
  font-size: 14px;
  border: 1px solid #e6e6e6;
}
.yimiao .buttons {
  text-align: center;
}
.yimiao .buttons button {
  width: 90%;
  margin: 25px 5%;
  border-radius: 10px;
}
.buttons {
  text-align: center;
}
.buttons button {
  margin: 25px 10px;
  border-radius: 10px;
}
</style>